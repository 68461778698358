<template>
    <div class="card card-custom h-100">
        <!--begin::Body-->
        <div v-if="!isBusy" class="card-body">
            <!--begin::Top-->
            <div v-if="items">
                <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-45 symbol-light mr-5">
                        <span class="symbol-label">
                            <span class="svg-icon svg-icon-lg svg-icon-primary">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/General/Clipboard.svg"/>
                                <!--end::Svg Icon-->
                            </span>
                        </span>
                    </div>
                    <!--end::Symbol-->

                    <!--begin::Info-->
                    <div class="d-flex flex-column flex-grow-1">
                        <span class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">Setting HE</span>
                        <div class="d-flex flex-wrap">
                            <div class="d-flex align-items-center pr-5">
                                <span class="svg-icon svg-icon-md svg-icon-primary pr-1">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Home/Clock.svg"/>
                                    <!--end::Svg Icon-->
                                </span>
                                <span class="text-muted font-weight-bold">{{ items.tgl_setting_he | dateIndonesia }}</span>
                            </div>
                            <div class="d-flex">
                                <b-badge :variant="items.sts.color">{{ items.sts.uraian }}</b-badge>
                            </div>
                        </div>
                        <div class="d-flex flex-wrap">
                            <div class="d-flex align-items-center pr-5">
                                <span class="svg-icon svg-icon-md svg-icon-primary pr-1">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Layout/Layout-grid.svg"/>
                                    <!--end::Svg Icon-->
                                </span>
                                <span class="text-muted font-weight-bold">{{ items.jumlah | nominal }} butir</span>
                            </div>
                        </div>
                    </div>
                    <!--end::Info-->
                </div>
                <!--end::Top-->
                <perfect-scrollbar
                class="scroll"
                style="max-height: 98%; position: relative;"
                >

                <!--begin::Bottom-->
                <div class="pt-3 p-0">
                    <!--begin::Text-->
                    <p v-if="items.keterangan" class="text-dark-75 font-size-lg font-weight-normal pt-5 mb-6">
                        {{ items.keterangan }}
                    </p>
                    <!--end::Text-->

                    <!--begin::Action-->
                    <div class="pt-6 mb-4">
                        <a class="btn btn-light-primary btn-sm rounded font-weight-bolder font-size-sm px-4 p-2">
                            <span class="svg-icon svg-icon-md">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Devices/Homepod.svg"/>
                                <!--end::Svg Icon-->
                            </span>          
                            {{ items.pullchick.length }} PullChick
                        </a>
                    </div>
                    <!--end::Action-->

                    <!--begin::Item-->
                    <div v-if="items.pullchick.length > 0">
                        <div v-for="(val, key) in items.pullchick" :key="key" class="d-flex pt-5 px-5 mb-2 bg-hover-light rounded">
                            <!--begin::Symbol-->
                            <div class="symbol d-none d-sm-flex symbol-40 symbol-light-success mr-5 mt-1">
                                <span class="symbol-label">
                                <span class="svg-icon svg-icon-info svg-icon-md">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Devices/Homepod.svg"/>
                                    <!--end::Svg Icon-->
                                </span>         
                                </span>
                            </div>
                            <!--end::Symbol-->

                            <!--begin::Info-->
                            <div class="d-flex flex-column flex-row-fluid">
                                <!--begin::Info-->
                                <div class="d-flex align-items-center flex-wrap">
                                    <span class="text-dark-75 text-hover-primary mb-1 font-size-md font-weight-bolder pr-6">
                                        PullChick {{ val.tgl_pullchick | dateIndonesia }}
                                    </span>
                                    <b-badge :variant="val.sts.color">{{ val.sts.uraian }}</b-badge>
                                </div>

                                <div class="d-flex align-items-center flex-wrap">
                                    <span class="text-primary font-weight-bold mr-2">
                                        Menetas:
                                    </span>
                                    <span class="text-muted font-weight-bold">{{ val.menetas | nominal }} ekor</span>
                                </div>

                                <div class="d-flex align-items-center flex-wrap">
                                    <span class="text-primary font-weight-bold mr-2">
                                        Culling:
                                    </span>
                                    <span class="text-muted font-weight-bold">{{ val.culling | nominal }} ekor</span>
                                </div>

                                <div v-for="(itm, ids) in val.saleable" :key="ids" class="d-flex align-items-center flex-wrap">
                                    <span class="text-primary font-weight-bold mr-2">
                                        {{ itm.sex }}
                                    </span>
                                    <span class="text-muted font-weight-bold">{{ itm.jumlah | nominal }} ekor</span>
                                </div>

                                <span v-if="val.peringatan" class="text-danger font-size-sm font-weight-normal">
                                    {{ val.peringatan }}
                                </span>
                                <!--end::Info-->
                                <div class="separator separator-dashed separator-border-2 mt-1 mb-2"></div>

                            </div>
                            <!--end::Info-->

                        </div>
                    </div>
                    <div v-else>
                        <span class="text-muted font-size-lg font-weight-bolder">
                            Belum ada Pullchick
                        </span>
                    </div>
                    <!--end::Item-->
                </div>
                <!--end::Bottom-->
                </perfect-scrollbar>
            </div>
        </div>
        <div v-else class="card-body">
            <FacebookLoader></FacebookLoader>
        </div>
        <!--end::Body-->

        <div class="card-footer py-3">
            <button  
            @click="openDialogForm('RiwayatPullchick', sdata)"
            class="btn btn-size-sm btn-primary font-weight-bold m-3">
                <span class="svg-icon svg-icon-md svg-icon-white">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Home/Book-open.svg" />
                    <!--end::Svg Icon-->
                </span>
                Riwayat
            </button>

            <button
            class="btn btn-size-sm btn-clean font-weight-bold m-3"
            type="button"
            @click="closeDialog()">
                <i class="la la-recycle"></i>
                Close
            </button>
        </div>

        <v-dialog v-model="dialogDetail" persistent scrollable max-width="500px">
            <component v-if="curComponent" :sdata="sdataForm" @close-modal="closeDialogForm" v-bind:is="curComponent"></component>
        </v-dialog>
    </div>
</template>

<script>
import { FacebookLoader } from 'vue-content-loader'
import ProduksiService from "@/core/services/api/nasional/produksi.service";
import ErrorService from "@/core/services/error.service";

import RiwayatPullchick from "@/view/pages/nasional/produksi/form/riwayat/_Pullchick.vue";

export default {
    name: "unggas-nasional-data-produksi-form-detail",
    props: {
        sdata: {
            required: true
        }
    },
    components: {
        FacebookLoader,
        RiwayatPullchick
    },
    data() {
        return {
            dialogDetail: false,
            curComponent: null,
            sdataForm: null,
            isBusy: true,
            items: null,
        }
    },
    methods: {
        closeDialog() {
            return this.$emit('close-modal', {status: true, refresh: false})
        },
        openDialogForm(name, data) {
            this.curComponent = name
            this.sdataForm = data
            this.dialogDetail = true
        },
        closeDialogForm(res) {
            if (res.status) {
                if (res.refresh) {
                    this.getData()    
                }
                this.curComponent = null
                this.sdataForm = null
                this.dialogDetail = false
            }
        },
        getData () {
            ProduksiService.getSettingHeDetailList({setting: this.sdata._id})
            .then((res) => {
                if (!res.data.status) {
                    ErrorService.message(res.data)
                    this.items = null
                    this.isBusy = false
                    return;
                }
                this.items = res.data.data
                this.isBusy = false
            })
            .catch((err) => {
                this.items = null
                this.isBusy = false
                return ErrorService.status(err)
            })
        },
    },
    mounted() {
        this.getData()
    },
}
</script>